import { Route, Routes } from "react-router-dom";

import cx from "classnames";
import "./App.css";
import { Home, Login, Signup } from "./pages/public";
import { CheckAuth, useDarkMode } from "./hooks";
import { Nav } from "./components/global";
import TestPage from "./pages/protected/TestPage";
import { MapboxMap } from "./components/local/Maps";


const App = () => {
    const { darkMode } = useDarkMode();

    return (
        // global layout
        <div
            data-cy="main-background"
            className={cx(
                `bg-forestgreen-${darkMode ? "800" : "200"}`,
                `text-gray-${darkMode ? "200" : "800"}`,
                "flex w-full min-h-screen transition duration-300 ease-in-out"
            )}
        >
            {/* nav bar */}
            <Nav />

            {/* router layout */}
            <Routes>
                {/* public */}
                <Route path="/" element={<Home header="home" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Signup />} />
                <Route
                    path="/maps"
                    element={
                        <CheckAuth>
                            <MapboxMap />
                        </CheckAuth>
                    }
                />

                {/* protected */}
                <Route
                    path="/protected"
                    element={
                        <CheckAuth>
                            <TestPage />
                        </CheckAuth>
                    }
                />
            </Routes>
        </div>
    );
};

export default App;
