// internal
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { SetAtom } from "@/types/global";

import { zodResolver } from "@hookform/resolvers/zod";
import { MutableRefObject, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

export default function BuildingHeightDataDialog({
  dialogOpen,
  setDialogOpen,
  drawRef,
  selectedPolygon,
}: {
  dialogOpen: boolean;
  setDialogOpen: SetAtom<[SetStateAction<boolean>], void>;
  drawRef: MutableRefObject<MapboxDraw | null>;
  selectedPolygon: string;
}) {
  const formSchema = z.object({
    buildingHeight: z.coerce.number(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      buildingHeight: 100,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);
    drawRef.current?.setFeatureProperty(
      selectedPolygon,
      "buildingHeight",
      values.buildingHeight
    );
    setDialogOpen(false);
  }

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogContent
        className="sm:max-w-[425px]"
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        onEscapeKeyDown={() => {
          drawRef.current?.delete(selectedPolygon);
        }}
      >
        <DialogHeader>
          <DialogTitle>Specify Height</DialogTitle>
          <DialogDescription>
            Enter the building height in metres for
            <a className="font-bold">{selectedPolygon}</a>
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="buildingHeight"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Building height</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Height in metres"
                      {...field}
                      type={"number"}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type="submit">Save changes</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
