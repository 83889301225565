import React from "react";

type AppProps = {
  header: string;
};

const Home: React.FunctionComponent<AppProps> = ({ header }) => {
  return <div>{header}</div>;
};

export default Home;
