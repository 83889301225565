/**
 * ? All identifiers for source and layers on base map layer
 */
export const buildingSource = "composite";
export const buildingSourceLayer = "building";
export const buildingID = "3D-buildings";

export const unconstructedBuildingSource = `unconstructed-buildings-source`;
export const unconstructedBuildingLayerID = `unconstructed-buildings-layer`;

export const holesSource = `holes-source`;
export const holesLayerID = `holes-layer`;

export const roofLayerSource = `roofLayerSource`;
export const roofLayerID = `roofLayerID`;

/**
 * ? Constants
 */

// drawing
export enum MapboxModes {
  DEFAULT_MODE = "simple_select",
  DRAWING_MODE = "draw_polygon",
  SPLIT_POLYGON_MODE = "split_polygon",
  DIRECT_SELECT = "direct_select",
  CUT_POLYGON = "cut_polygon",
  UNION_POLYGON = "union",
}

// drawingModes
export enum DrawingModes {
  VIEW_MODE = "view_mode",
  CONSTRUCTED_MODE = "constructed_mode",
  UNCONSTRUCTED_MODE = "unconstructed_mode",
}

// buildingType
export enum BuildingTypes {
  CONSTRUCTED = "constructed",
  UNCONSTRUCTED = "unconstructed",
}

// selection Mapping
export enum FinancialRoofTypeSelections {
  METAL = 0,
  CERAMIC = 1,
  CONCRETE = 2,
}

export enum FinancialRoofTypeSelectionsString {
  METAL = "METAL",
  CERAMIC = "CERAMIC",
  CONCRETE = "CONCRETE",
}

export enum FinancialUsageSelections {
  HALFDAY_HALFNIGHT = 0,
  DAY = 1,
  NIGHT = 2,
}

export enum FinancialUsageSelectionsString {
  HALFDAY_HALFNIGHT = "HALFDAY_HALFNIGHT",
  DAY = "DAY",
  NIGHT = "NIGHT",
}

export enum StatusBadge {
  PENDING = "pending",
  GENERATED = "generated",
}

export interface FeatureProperties {
  status: StatusBadge;
  buildingHeight: number;
  panelCount: number;
  capacityKwp: number;
  panelPower: number;
  roofType: FinancialRoofTypeSelectionsString;
  spProvider: boolean;
  monthlyBill: number;
  usage: FinancialUsageSelectionsString;
  financials: {
    annual_carbon_savings_tons: number;
    trees_planted: number;
    max_annual_kwhac: number;
    payback_year: number;
    max_panels: number;
    max_annual_savings_revenue: number;
    investment: number;
  };
}
