import { createStore, Provider } from "jotai"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App.tsx"
import "./index.css"
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


const store = createStore()

ReactDOM.createRoot(document.getElementById("root")!).render(
    // <React.StrictMode>
    <Provider store={store}>
        <BrowserRouter>
            <App />
            <ToastContainer />
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
)
