// external
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MeasureBuildingHeightTopBaseIcon from "calcite-ui-icons-react/MeasureBuildingHeightTopBaseIcon";
import TrashIcon from "calcite-ui-icons-react/TrashIcon";
import cx from "classnames";
import { useAtom } from "jotai";
import { PackagePlus } from "lucide-react";
import { Map } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useRef } from "react";

// using "development" or "production" env files
console.log(import.meta.env.MODE);

import { fetch_panels } from "@/api/calls";
import { useDarkMode } from "@/hooks/useDarkMode";
import {
  buildUpTypeAtom,
  currentPolygonSelectedAtom,
  finalDrawingFeatureCollectionAtom,
  getHeightDialogAtom,
  initialRoofFeatures,
  mapModeAtom,
  selectedFeaturesAtom,
} from "@/states/atoms";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { BuidingMode } from "./components/BuidingMode";
import BuildingHeightDataDialog from "./components/BuildingHeightDialog";
import DrawingDataDialog from "./components/DrawingDataDialog";
import {
  MapboxToolbar,
  removeAllSelectedPolygons,
} from "./components/MapboxToolbar";
import {
  buildingID,
  MapboxModes,
  unconstructedBuildingLayerID,
} from "./data/identifier";
import {
  generateBuilding,
  generatePanelsOnBuildings,
} from "./handlers/GenerateSourceLayer";
import { initMap } from "./handlers/InitMap";

/**
 * ! API Keys and Access Tokens
 */
const MapboxMap = () => {
  /**
   * ? Refs
   */
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);
  const centerPoint = useRef<[string, string] | null>(null);

  /**
   * ? Hooks
   */
  const { darkMode } = useDarkMode();
  const [mapMode, setMapMode] = useAtom(mapModeAtom);
  const [numSelectedFeatures, setNumSelectedFeatures] =
    useAtom(selectedFeaturesAtom);
  const [roofFeatures, setRoofFeatures] = useAtom(initialRoofFeatures);
  const [, setFinalDrawFC] = useAtom(finalDrawingFeatureCollectionAtom);
  const [dialogOpen, setDialogOpen] = useAtom(getHeightDialogAtom);

  const [selectedPolygon, setSelectedPolygon] = useAtom(
    currentPolygonSelectedAtom
  );
  const [buildingMode, setBuildingMode] = useAtom(buildUpTypeAtom);

  /**
   * ? TW Style Classnames
   */
  // ? map parent wrapper class - for toggling light dark mode border effect
  const MapWrapperClass = cx(
    "w-[80%] h-[80%] px-[0.3svw] py-[0.3svw] grid fixed place-self-center rounded-3xl",
    `bg-forestgreen-${darkMode ? "200" : "800"}`
  );
  // ? map container class - inheriting positioning and size of parent wrapper class
  const MapClass = cx(
    "w-[100%] h-[100%] relative place-self-center rounded-3xl"
  );

  // ? map current state text
  const MapStateTextClass = cx(
    `bg-forestgreen-${darkMode ? "200" : "800"}`,
    `text-${darkMode ? "black" : "white"}`,
    "absolute grid justify-self-end text-2xl font-bold h-8 w-48 bottom-1 left-1 z-[100]  rounded-tr-lg rounded-bl-2xl text-center content-center select-none"
  );

  // ? toolbar delete polygon button
  const DeletePolygonButtonClass = cx(
    `absolute bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500 bottom-5 right-[1%]`,
    numSelectedFeatures > 0 ? "visible" : "invisible"
  );

  // ? toolbar delete polygon button
  const GeneratePanelsButtonClass = cx(
    `absolute bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500 bottom-5 right-[9%]`,
    numSelectedFeatures > 0 ? "visible" : "invisible"
  );

  // ? toolbar delete polygon button
  const GenerateBuildingsButtonClass = cx(
    `absolute bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500 bottom-5 right-[13%]`,
    numSelectedFeatures > 0 ? "visible" : "invisible"
  );

  // ? init base map with 3D building extrusions
  if (!mapRef.current && mapContainerRef) {
    initMap(
      mapRef,
      mapContainerRef,
      drawRef,
      setMapMode,
      [103.8198, 1.3521],
      setNumSelectedFeatures,
      setDialogOpen,
      setSelectedPolygon,
      buildingMode
    );
  }

  return (
    <div className="w-screen h-screen grid place-content-center">
      {/* // ! geocoder component */}
      <div
        id="geocoder"
        className={`geocoder absolute grid place-self-center top-[5%] right-[10%]`}
      />
      {/* // ! map component */}
      <div className={MapWrapperClass}>
        <div className={MapClass} ref={mapContainerRef} id={"map-capture"} />
        {/* // ! drawing Toolbar and map drawing mode selector contained within the map wrapper*/}
        <MapboxToolbar
          mode={mapMode}
          setMapMode={setMapMode}
          mapRef={mapRef}
          drawRef={drawRef}
          numSelectedFeature={numSelectedFeatures}
          setNumSelectedFeature={setNumSelectedFeatures}
        />
        {/* // ! Show Map Mode for debugging */}
        <div className={MapStateTextClass}>
          <h3>
            {!drawRef.current?.getMode()
              ? MapboxModes.DEFAULT_MODE
              : drawRef.current?.getMode()}{" "}
            + {numSelectedFeatures}
          </h3>
        </div>
        <button
          title="Generate building"
          className={GenerateBuildingsButtonClass}
          onClick={async () => {
            generateBuilding(drawRef, mapRef, buildingMode);
            await fetch_panels(drawRef, setRoofFeatures, centerPoint);
          }}
        >
          <MeasureBuildingHeightTopBaseIcon />
        </button>
        {/* // ! Button for generating panels */}
        <button
          title="Generate Panels"
          className={GeneratePanelsButtonClass}
          onClick={() => {
            generatePanelsOnBuildings(mapRef, roofFeatures, buildingMode);
          }}
        >
          <PackagePlus />
        </button>
        {/* // ! for displaying the datatable dialog */}
        <DrawingDataDialog
          drawRef={drawRef}
          setFinalDrawFC={setFinalDrawFC}
          setSelectedPolygon={setSelectedPolygon}
          selectedPolygon={selectedPolygon}
          centerPoint={centerPoint}
        />

        {/* // ! for displaying dialog option for adding building height */}
        <BuildingHeightDataDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          drawRef={drawRef}
          selectedPolygon={selectedPolygon}
        />
        {/* // ! Toggle display between constructed and unconstructed buildings */}
        <BuidingMode
          drawRef={drawRef}
          mapRef={mapRef}
          buildingID={buildingID}
          unconstructedBuildingLayerID={unconstructedBuildingLayerID}
          setBuildingMode={setBuildingMode}
          setMapMode={setMapMode}
        />
        {/* // ! Delete selected drawings */}
        <button
          title="Delete"
          className={DeletePolygonButtonClass}
          onClick={() => {
            removeAllSelectedPolygons(mapRef, drawRef, setNumSelectedFeatures);
          }}
        >
          <TrashIcon />
        </button>

        {/* // ? for debugging or future use */}
        {/* <button
                    title="Generate building"
                    className="fixed bottom-[10%] bg-black right-[55%] text-white"
                    onClick={() => {
                        generateBuilding(drawRef, mapRef, buildingMode);
                    }}
                >
                    Generate building
                </button>
                <button
                    title="Hit Backend Endpoint"
                    className="fixed bottom-[10%] bg-black right-[50%] text-white"
                    onClick={async () => {
                        await fetch_panels(drawRef, setRoofFeatures, centerPoint);
                    }}
                >
                    submit
                </button> */}
        {/* <button onClick={() => {
                    html2canvas(document.querySelector("#map-capture"), {
                        canvas: document.querySelector("#placeholder"),
                        scale: 0.2
                    })
                }} className="absolute bottom-[30%] z-10 bg-white">SS</button>
                <button onClick={() => {
                    function to_image() {
                        var canvas = document.getElementById("placeholder");
                        console.log(canvas.toDataURL())
                    }
                    to_image()

                }} className="absolute bottom-[20%] z-10 bg-white">SS To Image</button>
                <canvas id="placeholder" className="flex absolute bottom-[15%] right-[10%]">
                </canvas> */}
        {/* <button
                    title="Hit Backend Endpoint"
                    className="fixed bottom-[10%] bg-black right-[15%] text-white"
                    onClick={() => {
                        setGraphView(!graphView)
                    }}
                >
                    show graph and pdf download icon
                </button> */}
      </div>
    </div>
  );
};

export default MapboxMap;
