import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { hasFeatureProperties, isPolygon } from "@/lib/utils";
import { SetAtom } from "@/types/global";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { cx } from "class-variance-authority";
import { Feature, FeatureCollection, Polygon } from "geojson";
import fileDownload from "js-file-download";
import {
  BrickWall,
  Download,
  Ellipsis,
  FileHeart,
  MoonStar,
  NotepadTextIcon,
  Sun,
  SunMoon,
} from "lucide-react";
import { MutableRefObject, SetStateAction, useRef } from "react";
import { useForm } from "react-hook-form";
import { Id, toast } from "react-toastify";
import { z } from "zod";
import {
  FeatureProperties,
  FinancialRoofTypeSelections,
  FinancialRoofTypeSelectionsString,
  FinancialUsageSelections,
  FinancialUsageSelectionsString,
  StatusBadge,
} from "../data/identifier";

const DrawingDataDialog = ({
  drawRef,
  setFinalDrawFC,
  setSelectedPolygon,
  selectedPolygon,
  centerPoint,
}: {
  drawRef: MutableRefObject<MapboxDraw | null>;
  setFinalDrawFC: SetAtom<[SetStateAction<FeatureCollection>], void>;
  setSelectedPolygon: SetAtom<[SetStateAction<string>], void>;
  selectedPolygon: string;
  centerPoint: MutableRefObject<[string, string] | null>;
}) => {
  const toastId = useRef<Id>("");

  // ? toolbar delete polygon button
  const DataInfoButtonClass = cx(
    `absolute bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500 bottom-5 right-[5%]`
  );
  const editFormSchema = z.object({
    panelPower: z.coerce.number(),
    roofType: z.nativeEnum(FinancialRoofTypeSelectionsString),
    spProvider: z.enum(["true", "false"]),
    monthlyBill: z.coerce.number(),
    usage: z.nativeEnum(FinancialUsageSelectionsString),
  });

  const editForm = useForm<z.infer<typeof editFormSchema>>({
    resolver: zodResolver(editFormSchema),
    defaultValues: {
      panelPower: 0,
      roofType: FinancialRoofTypeSelectionsString.METAL,
      spProvider: "true",
      monthlyBill: 0,
      usage: FinancialUsageSelectionsString.HALFDAY_HALFNIGHT,
    },
  });

  function editFormOnSubmit(values: z.infer<typeof editFormSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    console.log(values);
    drawRef.current?.setFeatureProperty(
      selectedPolygon,
      "panelPower",
      values.panelPower
    );
    drawRef.current?.setFeatureProperty(
      selectedPolygon,
      "roofType",
      values.roofType
    );
    drawRef.current?.setFeatureProperty(
      selectedPolygon,
      "spProvider",
      values.spProvider
    );
    drawRef.current?.setFeatureProperty(
      selectedPolygon,
      "monthlyBill",
      values.monthlyBill
    );
    drawRef.current?.setFeatureProperty(selectedPolygon, "usage", values.usage);

    console.log(drawRef.current?.getAll());
  }

  const allDrawings = drawRef.current?.getAll();
  const polygonFeatures: Feature<Polygon, FeatureProperties>[] = [];
  allDrawings?.features.forEach((feature) => {
    if (isPolygon(feature) && hasFeatureProperties(feature)) {
      polygonFeatures.push(feature);
    }
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <button
          title="Building Information"
          className={DataInfoButtonClass}
          onClick={() => {
            const currentDrawings = drawRef.current?.getAll();
            console.log(currentDrawings);
            if (currentDrawings) {
              setFinalDrawFC(currentDrawings);
            }
          }}
        >
          <NotepadTextIcon />
        </button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[60%]">
        <DialogHeader>
          <DialogTitle>Edit Constructed/Unconstructed Buildings</DialogTitle>
          <DialogDescription>
            Additional Data fields to complete generation of buildings
          </DialogDescription>
        </DialogHeader>
        {allDrawings && allDrawings.features.length > 0 ? (
          <div className="grid gap-4 py-4">
            <Table>
              <TableCaption>
                A list of your recent building drawings.
              </TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[50px]">Drawing ID</TableHead>
                  <TableHead>Height</TableHead>
                  <TableHead>Number of Panels</TableHead>
                  <TableHead>Capacity KWH</TableHead>
                  <TableHead>Panel Power</TableHead>
                  <TableHead>Roof Type</TableHead>
                  <TableHead>SP Provider</TableHead>
                  <TableHead>Monthly Bill</TableHead>
                  <TableHead>Usage Pattern</TableHead>
                  <TableHead>Edges</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead className="text-right"></TableHead>
                  <TableHead className="text-right"></TableHead>
                  <TableHead className="text-right">Report</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="overflow-y-auto">
                {polygonFeatures.map((drawing) => (
                  <TableRow key={drawing.id}>
                    <TableCell className="font-medium">{drawing.id}</TableCell>
                    <TableCell>{drawing.properties.buildingHeight}</TableCell>
                    <TableCell>{drawing.properties.panelCount}</TableCell>
                    <TableCell>{drawing.properties.capacityKwp}</TableCell>
                    <TableCell>{drawing.properties.panelPower}</TableCell>
                    <TableCell>{drawing.properties.roofType}</TableCell>
                    <TableCell>{drawing.properties.spProvider}</TableCell>
                    <TableCell>{drawing.properties.monthlyBill}</TableCell>
                    <TableCell>{drawing.properties.usage}</TableCell>
                    <TableCell>
                      {drawing.geometry.coordinates[0].length}
                    </TableCell>
                    <TableCell>
                      <Badge
                        className={`${drawing.properties.status === StatusBadge.PENDING
                          ? "bg-red-700"
                          : "bg-green-800"
                          } select-none`}
                      >
                        {drawing.properties?.status}
                      </Badge>
                    </TableCell>
                    <TableCell className="text-right">
                      <Drawer>
                        <DrawerTrigger asChild>
                          <Ellipsis
                            className="h-6 w-6 p-1 rounded-lg bg-gray-300 cursor-pointer"
                            onClick={() => {
                              if (drawing.id) {
                                setSelectedPolygon(drawing.id.toString());
                              }
                            }}
                          />
                          {/* <Button onClick={(e) => {
                                                setSelectedPolygon(drawing.id)

                                            }}>Edit</Button> */}
                        </DrawerTrigger>

                        <DrawerContent className="flex justify-self-center w-[30%]">
                          <DrawerHeader>
                            <DrawerTitle>
                              Update building information
                            </DrawerTitle>
                            <DrawerDescription>
                              Information required to generate financial
                              information for the building selected
                            </DrawerDescription>
                          </DrawerHeader>
                          <Form {...editForm}>
                            <form
                              onSubmit={editForm.handleSubmit(editFormOnSubmit)}
                              className="space-y-8"
                            >
                              <div className="grid place-content-evenly grid-cols-2 gap-5 m-5">
                                <FormField
                                  // control={editForm.control}
                                  name="height"
                                  render={() => (
                                    <FormItem>
                                      <FormLabel>Height</FormLabel>
                                      <FormControl>
                                        <Input
                                          disabled
                                          placeholder="height"
                                          value={
                                            drawing.properties.buildingHeight
                                          }
                                          type={"number"}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  name="numberofpanels"
                                  render={() => (
                                    <FormItem>
                                      <FormLabel>Number of panels</FormLabel>
                                      <FormControl>
                                        <Input
                                          disabled
                                          placeholder="number of panels"
                                          value={drawing.properties.panelCount}
                                          type={"number"}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  name="capacitykwh"
                                  render={() => (
                                    <FormItem>
                                      <FormLabel>CapacityKWH</FormLabel>
                                      <FormControl>
                                        <Input
                                          disabled
                                          placeholder="power in watts"
                                          value={drawing.properties.capacityKwp}
                                          type={"number"}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={editForm.control}
                                  name="panelPower"
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>Panel Power</FormLabel>
                                      <FormControl>
                                        <Input
                                          placeholder="power in watts"
                                          {...field}
                                          type={"number"}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={editForm.control}
                                  name="roofType"
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>Roof Type</FormLabel>
                                      <FormControl>
                                        <ToggleGroup
                                          type="single"
                                          value={field.value}
                                          onValueChange={field.onChange}
                                        >
                                          <ToggleGroupItem
                                            title={
                                              FinancialRoofTypeSelectionsString.METAL
                                            }
                                            value={
                                              FinancialRoofTypeSelectionsString.METAL
                                            }
                                            aria-label="Toggle bold"
                                          >
                                            <BrickWall
                                              className="h-4 w-4"
                                              color="silver"
                                            />
                                          </ToggleGroupItem>
                                          <ToggleGroupItem
                                            title={
                                              FinancialRoofTypeSelectionsString.CERAMIC
                                            }
                                            value={
                                              FinancialRoofTypeSelectionsString.CERAMIC
                                            }
                                            aria-label="Toggle italic"
                                          >
                                            <BrickWall
                                              className="h-4 w-4"
                                              color="#d6d1d1"
                                            />
                                          </ToggleGroupItem>
                                          <ToggleGroupItem
                                            title={
                                              FinancialRoofTypeSelectionsString.CONCRETE
                                            }
                                            value={
                                              FinancialRoofTypeSelectionsString.CONCRETE
                                            }
                                            aria-label="Toggle underline"
                                          >
                                            <BrickWall
                                              className="h-4 w-4"
                                              color="#C4B6A6"
                                            />
                                          </ToggleGroupItem>
                                        </ToggleGroup>
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={editForm.control}
                                  name="spProvider"
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>SP Provider</FormLabel>
                                      <FormControl>
                                        <ToggleGroup
                                          type="single"
                                          value={field.value}
                                          onValueChange={field.onChange}
                                        >
                                          <ToggleGroupItem
                                            title="true"
                                            value="true"
                                            aria-label="Toggle bold"
                                          >
                                            <span className="h-4 w-4">T</span>
                                          </ToggleGroupItem>
                                          <ToggleGroupItem
                                            title="false"
                                            value="false"
                                            aria-label="Toggle italic"
                                          >
                                            <span className="h-4 w-4">F</span>
                                          </ToggleGroupItem>
                                        </ToggleGroup>
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={editForm.control}
                                  name="monthlyBill"
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>Monthly Bill</FormLabel>
                                      <FormControl>
                                        <Input
                                          placeholder="Monthly Bill for the building"
                                          {...field}
                                          type={"number"}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={editForm.control}
                                  name="usage"
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>Usage Pattern</FormLabel>
                                      <FormControl>
                                        <ToggleGroup
                                          type="single"
                                          value={field.value}
                                          onValueChange={field.onChange}
                                        >
                                          <ToggleGroupItem
                                            title="night and day"
                                            value={
                                              FinancialUsageSelectionsString.HALFDAY_HALFNIGHT
                                            }
                                            aria-label="Toggle bold"
                                          >
                                            <SunMoon
                                              className="h-4 w-4 stroke-gradient-to-r from-indigo-500"
                                              color="silver"
                                            />
                                          </ToggleGroupItem>
                                          <ToggleGroupItem
                                            title={
                                              FinancialUsageSelectionsString.DAY
                                            }
                                            value={
                                              FinancialUsageSelectionsString.DAY
                                            }
                                            aria-label="Toggle italic"
                                          >
                                            <Sun
                                              className="h-4 w-4"
                                              color="orange"
                                            />
                                          </ToggleGroupItem>
                                          <ToggleGroupItem
                                            title={
                                              FinancialUsageSelectionsString.NIGHT
                                            }
                                            value={
                                              FinancialUsageSelectionsString.NIGHT
                                            }
                                            aria-label="Toggle underline"
                                          >
                                            <MoonStar
                                              className="h-4 w-4"
                                              color="blue"
                                            />
                                          </ToggleGroupItem>
                                        </ToggleGroup>
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                              </div>
                              <DrawerFooter>
                                <DrawerClose asChild>
                                  <Button type="submit">Submit</Button>
                                </DrawerClose>
                              </DrawerFooter>
                            </form>
                          </Form>
                        </DrawerContent>
                      </Drawer>
                    </TableCell>
                    <TableCell>
                      <FileHeart className="h-6 w-6 p-1 rounded-lg bg-gray-300 cursor-pointer" />
                    </TableCell>
                    <TableCell>
                      <Download
                        className="h-6 w-6 p-1 rounded-lg bg-gray-300 cursor-pointer"
                        onClick={() => {
                          alert("downloading file");
                          const payload = JSON.stringify({
                            carbon_savings:
                              drawing.properties?.financials
                                .annual_carbon_savings_tons,
                            trees: drawing.properties?.financials.trees_planted,
                            yearly_dc_generation:
                              drawing.properties?.financials.max_annual_kwhac,
                            payback_time:
                              drawing.properties?.financials.payback_year,
                            max_panels:
                              drawing.properties?.financials.max_panels,
                            annual_savings_sale_excess:
                              drawing.properties?.financials
                                .max_annual_savings_revenue,
                            installation_cost:
                              drawing.properties?.financials.investment,
                          });
                          axios
                            .post(
                              import.meta.env.PROD
                                ? `${process.env.ETAGEN_PDF_API}`
                                : "http://localhost:10000",
                              payload,
                              {
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                responseType: "blob",
                              }
                            )
                            .then((res) => {
                              console.log(res);
                              fileDownload(res.data, "etavolt-report.pdf");
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={12}>Total</TableCell>
                  <TableCell className="text-right">
                    <p className="font-bold text-3xl">
                      {polygonFeatures.length}
                    </p>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        ) : (
          ""
        )}

        <DialogFooter>
          <DialogClose>
            <Button
              type="submit"
              onClick={() => {
                const payloadSchema = z.object({
                  total_panel_count: z.coerce.number().min(1),
                  panel_power: z.coerce.number().min(1),
                  center_point: z.array(z.coerce.number()).nonempty(),
                  roof_type: z.coerce.number().min(0).max(2),
                  sp_provider: z.string(),
                  monthly_bill: z.coerce.number().min(1),
                  usage_pattern: z.coerce.number().min(0).max(2),
                  status: z.string(),
                });
                let validationError = null;
                polygonFeatures.forEach((feature) => {
                  const payload = {
                    total_panel_count: feature.properties.panelCount,
                    panel_power: feature.properties.panelPower,
                    center_point: centerPoint.current,
                    roof_type:
                      FinancialRoofTypeSelections[feature.properties.roofType],
                    sp_provider: feature.properties.spProvider,
                    monthly_bill: feature.properties.monthlyBill,
                    usage_pattern:
                      FinancialUsageSelections[feature.properties.usage],
                    status: "OK",
                  };

                  try {
                    payloadSchema.parse(payload);
                  } catch (error) {
                    validationError = error;
                  }
                });
                if (validationError) {
                  alert(validationError);
                  return;
                }

                let progress = 0;
                const length = polygonFeatures.length;

                polygonFeatures?.forEach(async (feature, i) => {
                  const featureId = feature.id?.toString() ?? "";

                  const payload = {
                    total_panel_count: feature.properties.panelCount,
                    panel_power: feature.properties.panelPower,
                    center_point: centerPoint.current,
                    roof_type:
                      FinancialRoofTypeSelections[feature.properties.roofType],
                    sp_provider: feature.properties.spProvider,
                    monthly_bill: feature.properties.monthlyBill,
                    usage_pattern:
                      FinancialUsageSelections[feature.properties.usage],
                    status: "OK",
                  };
                  console.log(payloadSchema.parse(payload));

                  const results = await axios
                    .post(
                      import.meta.env.PROD
                        ? `${process.env.ETAGEN_API}/finance/mapbox_results/`
                        : "http://localhost:8080/finance/mapbox_results/",
                      payload
                    )
                    .then((res) => {
                      drawRef.current?.setFeatureProperty(
                        featureId,
                        "status",
                        "generated"
                      );
                      progress = (i + 1) / length;

                      if (length === 1) {
                        toast.success(
                          `fetched financial data for ${length} buildings`,
                          { position: "bottom-right" }
                        );
                        return res.data
                      }
                      if (i === 0) {
                        toastId.current = toast("getting financial info", {
                          progress: progress,
                          position: "bottom-right",
                        });
                      } else {
                        toast.update(toastId.current, { progress: progress });
                      }

                      if (progress === 1) {
                        toast.done(toastId.current);

                        toast.success(
                          `fetched financial data for ${length} buildings`,
                          { position: "bottom-right" }
                        );
                      }

                      return res.data;
                    })
                    .catch((err) => {
                      console.log(err);
                      toast.error(err, { position: "bottom-right" });
                    });

                  drawRef.current?.setFeatureProperty(
                    featureId,
                    "financials",
                    results
                  );
                });
              }}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DrawingDataDialog;
