import { SetAtom } from "@/types/global";
import MapboxDraw, {
  DrawCreateEvent,
  DrawSelectionChangeEvent,
} from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl, { GeoJSONFeature, LngLatLike, Map } from "mapbox-gl";
import { MutableRefObject, SetStateAction } from "react";
import {
  buildingID,
  buildingSource,
  buildingSourceLayer,
  BuildingTypes,
  MapboxModes,
  unconstructedBuildingLayerID,
} from "../data/identifier";

// @ts-expect-error ignore js lib imports
import defaultDrawStyle from "https://unpkg.com/@mapbox/mapbox-gl-draw@1.3.0/src/lib/theme.js";

import SelectFeatureMode, {
  drawStyles as selectFeatureDrawStyles,
  // @ts-expect-error ignore js lib imports
} from "mapbox-gl-draw-select-mode";

import SplitPolygonMode, {
  drawStyles as splitPolygonDrawStyles,
  // @ts-expect-error ignore js lib imports
} from "@/lib/mapbox-gl-draw-split-polygon-mode";

import CutPolygonMode, {
  drawStyles as cutPolygonDrawStyles,
  // @ts-expect-error ignore js lib imports
} from "@/lib/mapbox-gl-draw-cut-polygon-mode";

// @ts-expect-error ignore js lib imports
import UnionMode from "@/lib/mapbox-gl-draw-union-mode";

import { styles } from "@/lib/mapbox-gl-defaults/index.ts";

const mapbox_access_token = process.env.ETAGEN_MAPBOXACCESSTOKENDEV;

export const initMap = (
  mapRef: MutableRefObject<Map | null>,
  mapContainerRef: MutableRefObject<HTMLDivElement | null>,
  drawRef: MutableRefObject<MapboxDraw | null>,
  setMapMode: SetAtom<[SetStateAction<MapboxModes>], void>,
  LatLng: LngLatLike,
  setNumSelectedFeatures: SetAtom<[SetStateAction<number>], void>,
  setDialog: SetAtom<[SetStateAction<boolean>], void>,
  setSelectedPolygon: SetAtom<[SetStateAction<string>], void>,
  buildingMode: BuildingTypes
) => {
  // ? 1. add accesstoken for mapbox usage
  if (!mapbox_access_token || !mapContainerRef.current) {
    return;
  }
  // ? 2. create base layer map
  mapRef.current = new mapboxgl.Map({
    accessToken: mapbox_access_token,
    container: mapContainerRef.current,
    style: process.env.ETAGEN_MAPBOX_STYLE,
    center: LatLng,
    minZoom: 2,
    // zoom: 18,
    zoom: 10.5,
    // only if under the standard or premium pricing plans then this can work
    attributionControl: false,
    preserveDrawingBuffer: true,
  });

  // ? 3. extrude existing 3D-building layer on top of map base layer
  mapRef.current.on("load", () => {
    if (!mapRef.current) {
      return;
    }
    // ? add building extrusion and enable color selection and toggling with feature state
    mapRef.current.addLayer({
      id: buildingID,
      source: buildingSource,
      "source-layer": buildingSourceLayer,
      layout: {
        visibility: "none",
      },
      filter: ["==", "extrude", "true"],
      type: "fill-extrusion",
      minzoom: 15,
      paint: {
        // required to toggle building color state
        "fill-extrusion-color": [
          "case",
          [
            "boolean",
            ["feature-state", "click"],
            ["feature-state", "hover"],
            false,
          ],
          "#64bdbb",
          "#FFFFFF",
        ],
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "height"],
        ],
        "fill-extrusion-base": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "min_height"],
        ],
        "fill-extrusion-opacity": 0.8,
      },
    });

    buildingMode === BuildingTypes.CONSTRUCTED &&
      mapRef.current?.setLayoutProperty(buildingID, "visibility", "visible");

    // ? adding in built in geocoder functionality from mapbox
    const geocoder = new MapboxGeocoder({
      accessToken: mapbox_access_token,
      flyTo: {
        duration: 2500,
        zoom: 19,
        speed: 10,
        bearing: 0,
        curve: 1,
        essential: true,
        easing: function (t) {
          return t;
        },
      },
      //   mapboxgl: mapboxgl,
    });

    document
      .getElementById("geocoder")
      ?.appendChild(geocoder.onAdd(mapRef.current as any));

    drawRef.current = new MapboxDraw({
      defaultMode: MapboxModes.DEFAULT_MODE,
      displayControlsDefault: false,
      modes: {
        ...SplitPolygonMode(SelectFeatureMode(MapboxDraw.modes)),
        ...CutPolygonMode(MapboxDraw.modes),
        ...Object.assign(MapboxDraw.modes, {
          union: UnionMode,
        }),
      },
      styles: [
        ...splitPolygonDrawStyles(selectFeatureDrawStyles(defaultDrawStyle)),
        ...cutPolygonDrawStyles(defaultDrawStyle),
        ...styles,
      ],
      userProperties: true,
    });

    // add the drawing manager as a control to the map
    mapRef.current.addControl(drawRef.current, "top-left");
    mapRef.current.on("draw.create", (e: DrawCreateEvent) => {
      drawRef.current?.setFeatureProperty(
        e.features[0]["id"] as string,
        "status",
        "pending"
      );

      setSelectedPolygon(e.features[0]["id"] as string);
      setMapMode(MapboxModes.DEFAULT_MODE);
      setDialog(true);
    });

    mapRef.current.on("draw.selectionchange", (e: DrawSelectionChangeEvent) => {
      if (e.features.length > 0) {
        console.log(e);
        // setNumSelectedFeatures(e.features.length);
        console.log(drawRef.current?.getSelectedIds().length);
        setNumSelectedFeatures(
          drawRef.current?.getSelectedIds().length as number
        );
      }
    });
    mapRef.current.on("draw.modechange", (e) => console.log(e));
    mapRef.current.on("click", unconstructedBuildingLayerID, (e) => {
      const feature = e.features as GeoJSONFeature[];
      console.log(drawRef.current?.get(feature[0].id as string));
    });
    mapRef.current.on("click", (e) => {
      console.log(e);
    });

    return () => {
      mapRef.current?.remove();
      document.getElementById("geocoder")?.remove();
    };
  });
};
