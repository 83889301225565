import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { House, HousePlus } from "lucide-react";
import { BuildingTypes, MapboxModes, roofLayerID } from "../data/identifier";
import { useDarkMode } from "@/hooks/useDarkMode";
import { cn } from "@/lib/utils";
import { changeMode } from "./MapboxToolbar";
import { MutableRefObject, SetStateAction } from "react";
import { SetAtom } from "@/types/global";
import { Map } from "mapbox-gl";

export function BuidingMode({
  drawRef,
  mapRef,
  buildingID,
  unconstructedBuildingLayerID,
  setBuildingMode,
  setMapMode,
}: {
  drawRef: MutableRefObject<MapboxDraw | null>;
  mapRef: MutableRefObject<Map | null>;
  buildingID: string;
  unconstructedBuildingLayerID: string;
  setBuildingMode: SetAtom<[SetStateAction<BuildingTypes>], void>;
  setMapMode: SetAtom<[SetStateAction<MapboxModes>], void>;
}) {
  /**
   * ? Hooks
   */
  const { darkMode } = useDarkMode();

  const ParentWrapper = cn(
    "grid w-full grid-cols-2 border-forestgreen-800 border-[3px] h-[10%]",
    `border-forestgreen-${darkMode ? "200" : "800"}`
  );

  return (
    <Tabs
      defaultValue="unconstructed"
      className="w-[400px] absolute flex justify-self-center pt-[2%]"
    >
      <TabsList className={ParentWrapper}>
        <TabsTrigger
          title="Using pre created buildings"
          value="constructed"
          onClick={() => {
            changeMode(MapboxModes.DEFAULT_MODE, setMapMode, drawRef, mapRef);
            setBuildingMode(BuildingTypes.CONSTRUCTED);
            mapRef.current?.setLayoutProperty(
              buildingID,
              "visibility",
              "visible"
            );
            mapRef.current?.setLayoutProperty(
              unconstructedBuildingLayerID,
              "visibility",
              "none"
            );
            mapRef.current?.setLayoutProperty(
              roofLayerID,
              "visibility",
              "none"
            );

            // console.log(mapRef.current?.queryRenderedFeatures())
          }}
        >
          <div className="flex space-x-2">
            <p>Constructed</p>
            <House />
          </div>
        </TabsTrigger>
        <TabsTrigger
          title="Design your own building from scratch"
          value="unconstructed"
          onClick={() => {
            setMapMode(MapboxModes.DEFAULT_MODE);
            setBuildingMode(BuildingTypes.UNCONSTRUCTED);
            mapRef.current?.setLayoutProperty(buildingID, "visibility", "none");
            mapRef.current?.setLayoutProperty(
              unconstructedBuildingLayerID,
              "visibility",
              "visible"
            );
            mapRef.current?.setLayoutProperty(
              roofLayerID,
              "visibility",
              "visible"
            );
          }}
        >
          <div className="flex space-x-2">
            <p>Unconstructed</p>
            <HousePlus />
          </div>{" "}
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
