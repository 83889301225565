import { ExtractAtomValue, useAtom } from "jotai";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { authAtom } from "@/states/atoms";

type authed = ExtractAtomValue<typeof authAtom>;

interface Iauthed {
  authed: authed;
  login(): Promise<void>;
  logout(): Promise<void>;
}

export const useAuth = (): Iauthed => {
  const [authed, setAuthed] = useAtom(authAtom);

  console.log("auth status: ", authed);

  return {
    authed,
    login() {
      return new Promise<void>((res) => {
        setAuthed(true);
        res();
      });
    },
    logout() {
      return new Promise<void>((res) => {
        setAuthed(false);
        res();
      });
    },
  };
};

type CheckAuthProps = {
  children: React.ReactNode;
};

export function CheckAuth({ children }: CheckAuthProps) {
  const { authed } = useAuth();
  const location = useLocation();
  console.log(authed);

  // using Navigate as a Component instead of a hook, pass in previous navigational data as info
  return authed === true ? (
    children
  ) : (
    <Navigate to={"/login"} state={location} replace={true} />
  );
}
