import { useDarkMode } from "@/hooks";
import { useAuth } from "@/hooks/useAuth";
import { cx } from "class-variance-authority";
import { useNavigate } from "react-router-dom";
import { DropDown } from "./Dropdown";

const Nav = () => {
  const { darkMode, setDarkMode } = useDarkMode();
  const { authed } = useAuth();
  const nav_to = useNavigate();

  // classes
  const LightDarkClass = cx(
    darkMode ? "bg-white text-white" : "bg-black text-black"
  );

  const LightDarkHoverClass = cx(
    darkMode ? "text-white hover:bg-black" : "text-black hover:bg-white"
  );

  const toggleLightDarkClass = cx(
    LightDarkClass,
    "inline-flex flex-shrink-0 justify-center items-center w-10 sm:w-12 h-10 sm:h-12 rounded-full opacity-75 text-xs sm:text-sm transition duration-300 ease-in-out"
  );

  const headerClass = cx(
    LightDarkHoverClass,
    "p-2 rounded-2xl w-15 sm:w-17 h-10 sm:h-12"
  );

  const svgImgClass = cx("w-[80%] h-[80%] select-none");

  const logoClass = cx(headerClass, "fixed left-[1%] top-[1%]");

  return (
    // div wrapper to contain nav items
    <>
      <div className={logoClass}>
        <button
          className="text-4xl font-bold italic"
          onClick={() => nav_to("/")}
        >
          EtaGen
        </button>
      </div>
      <div className="fixed right-[1%] top-[1%] flex items-center space-x-5">
        {/* light-dark mode toggle */}
        <button
          data-cy={"dark-light-mode-toggle"}
          onClick={() => setDarkMode(!darkMode)}
          className={toggleLightDarkClass}
        >
          <img
            alt="theme"
            src={darkMode ? "light-mode.svg" : "dark-mode.svg"}
            className={svgImgClass}
          />
        </button>

        {/* authed toggle to show/hide profile */}
        {authed ? (
          // profile button is wrapped in dropdown trigger listener
          <DropDown>
            <button
              className={toggleLightDarkClass}
              onClick={() => nav_to("/profile")}
            >
              <img
                alt="profile-pic"
                src={darkMode ? "profile-light.svg" : "profile-dark.svg"}
                className={svgImgClass}
              />
            </button>
          </DropDown>
        ) : (
          // sign in button default navigates to login
          <button className={headerClass} onClick={() => nav_to("/login")}>
            Sign In
          </button>
        )}
      </div>
    </>
  );
};

export default Nav;
