import { isPolygon } from "@/lib/utils";
import type { FeatureCollection, Polygon } from "geojson";
import { GeoJSONSource, Map } from "mapbox-gl";
import { MutableRefObject } from "react";
import {
  BuildingTypes,
  roofLayerID,
  roofLayerSource,
  unconstructedBuildingLayerID,
  unconstructedBuildingSource,
} from "../data/identifier";

interface BuildingProperties {
  id: string | number | undefined;
  "background-color": string;
  "line-color": string;
  buildingHeight: number;
  min_height: number;
}

export const generateBuilding = (
  drawRef: MutableRefObject<MapboxDraw | null>,
  mapRef: MutableRefObject<Map | null>,
  buildingMode: BuildingTypes
) => {
  const unprocessedDrawingFeatures = drawRef.current?.getAll();

  const buildingBodyFeatureCollection: FeatureCollection<
    Polygon,
    BuildingProperties
  > = {
    features: [],
    type: "FeatureCollection",
  };

  if (unprocessedDrawingFeatures) {
    unprocessedDrawingFeatures.features.forEach((feature) => {
      if (isPolygon(feature)) {
        buildingBodyFeatureCollection.features.push({
          id: feature.id,
          type: "Feature",
          properties: {
            id: feature.id,
            "background-color": "blue",
            "line-color": "black",
            buildingHeight: feature.properties?.buildingHeight,
            min_height: 0.5,
          },
          geometry: {
            type: "Polygon",
            coordinates: feature.geometry.coordinates,
          },
        });
      }
    });
  }

  if (!mapRef.current?.getSource(unconstructedBuildingSource)) {
    mapRef.current?.addSource(unconstructedBuildingSource, {
      type: "geojson",
      data: buildingBodyFeatureCollection,
      promoteId: "id",
      dynamic: true,
      tolerance: -10,
    });

    mapRef.current?.addLayer({
      id: unconstructedBuildingLayerID,
      source: unconstructedBuildingSource,
      type: "fill-extrusion",
      layout: {
        visibility: "none",
      },
      minzoom: 5,
      paint: {
        "fill-extrusion-color": "#64bdbb",
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "buildingHeight"],
        ],
        "fill-extrusion-base": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "min_height"],
        ],
        "fill-extrusion-opacity": 0.8,
      },
    });
  } else {
    mapRef.current
      ?.getSource<GeoJSONSource>(unconstructedBuildingSource)
      ?.setData(buildingBodyFeatureCollection);
  }
  buildingMode === BuildingTypes.UNCONSTRUCTED &&
    mapRef.current?.setLayoutProperty(
      unconstructedBuildingLayerID,
      "visibility",
      "visible"
    );
};

export const generatePanelsOnBuildings = (
  mapRef: MutableRefObject<Map | null>,
  roofFeatures: FeatureCollection,
  buildingMode: BuildingTypes
) => {
  const current_layer_id = roofLayerID;
  const current_source_id = roofLayerSource;
  if (mapRef.current?.getLayer(current_layer_id)) {
    mapRef.current?.removeLayer(current_layer_id);
  }
  if (mapRef.current?.getSource(current_source_id)) {
    mapRef.current?.removeSource(current_source_id);
  }

  /**
   * ? Adding geometry source layer either from simulated geojson file.
   * * this is where you add additional properties like colors etc
   */
  mapRef.current?.addSource(current_source_id, {
    type: "geojson",
    data: roofFeatures,
  });

  /**
   * ? Adding just the fill layer over all the polygons
   * * if layer exists, don't add new layer
   */
  mapRef.current?.addLayer({
    id: current_layer_id,
    type: "fill-extrusion",
    source: current_source_id,
    layout: {
      visibility: "none",
    },
    paint: {
      "fill-extrusion-color": ["get", "background-color"],
      "fill-extrusion-opacity": 1,
      "fill-extrusion-base": [
        "interpolate",
        ["linear"],
        ["zoom"],
        15,
        0,
        15.05,
        ["get", "buildingHeight"],
      ],

      "fill-extrusion-height": [
        "interpolate",
        ["linear"],
        ["zoom"],
        15,
        0,
        15.05,
        ["+", ["get", "buildingHeight"], ["get", "panelHeight"]],
      ],
    },
  });

  buildingMode === BuildingTypes.UNCONSTRUCTED &&
    mapRef.current?.setLayoutProperty(roofLayerID, "visibility", "visible");
};
