// external
// ? icons
import InformationIcon from "calcite-ui-icons-react/InformationIcon";
import PolygonVerticesIcon from "calcite-ui-icons-react/PolygonVerticesIcon";
import ResetIcon from "calcite-ui-icons-react/ResetIcon";
import SelectIcon from "calcite-ui-icons-react/SelectIcon";
import TrashIcon from "calcite-ui-icons-react/TrashIcon";
import MergeIcon from "calcite-ui-icons-react/MergeIcon";
import SelectionXIcon from "calcite-ui-icons-react/SelectionXIcon";
import ScissorsIcon from "calcite-ui-icons-react/ScissorsIcon";
import UngroupItemsIcon from "calcite-ui-icons-react/UngroupItemsIcon";
import GroupItemsIcon from "calcite-ui-icons-react/GroupItemsIcon";

// ? packages
import { AnimationSequence, useAnimate } from "framer-motion";
import { useAtom } from "jotai";
import { MutableRefObject, SetStateAction, useEffect } from "react";

// intenal
import { cn } from "@/lib/utils";
import { infoBoxIsOpenAtom } from "@/states/atoms";
import { SetAtom } from "@/types/global";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Map } from "mapbox-gl";
import { useDarkMode } from "../../../../hooks/useDarkMode";
import {
  buildingID,
  holesLayerID,
  holesSource,
  MapboxModes,
  roofLayerID,
  roofLayerSource,
  unconstructedBuildingLayerID,
  unconstructedBuildingSource,
} from "../data/identifier";

// ? helper to switch between drawing and selection mode on the map, and to also temporarily set building extrusion height to 0 during drawing mode
export const changeMode = (
  newMode: string,
  setMapMode: SetAtom<[SetStateAction<MapboxModes>], void>,
  drawRef: MutableRefObject<MapboxDraw | null>,
  mapRef: MutableRefObject<Map | null>
) => {
  if (!drawRef.current || !mapRef.current) {
    return;
  }
  console.log(newMode);
  try {
    drawRef.current.changeMode(newMode);
    setMapMode(newMode as MapboxModes);

    mapRef.current.setPaintProperty(
      buildingID,
      "fill-extrusion-height",
      drawRef.current.getMode() === MapboxModes.DRAWING_MODE
        ? 0
        : ["get", "height"]
    );
  } catch (err) {
    console.log(err);
  }
};

// ? helper to remove polygon from the map based on the polygonal identifier
const removePolygon = ({
  mapRef,
  polygonId,
}: {
  mapRef: MutableRefObject<Map | null>;
  polygonId: string;
}) => {
  if (!mapRef.current) {
    return;
  }
  // ? remove the layer and source of the hole
  if (mapRef.current.getLayer(holesLayerID + `:${polygonId}`)) {
    mapRef.current.removeLayer(holesLayerID + `:${polygonId}`);
  }

  if (mapRef.current.getSource(holesSource + `-${polygonId}`)) {
    mapRef.current.removeSource(holesSource + `-${polygonId}`);
  }
};

// ? A helper that gets all selected polygons and remove each of it in a loop
const removeAllSelectedPolygons = (
  mapRef: MutableRefObject<Map | null>,
  drawRef: MutableRefObject<MapboxDraw | null>,
  setNumSelectedFeatures: SetAtom<[SetStateAction<number>], void>
) => {
  if (!drawRef.current) {
    return;
  }
  const polygons = drawRef.current.getSelectedIds();
  polygons.forEach((polygon) => {
    removePolygon({ mapRef: mapRef, polygonId: polygon });
  });
  drawRef.current.trash();
  if (drawRef.current.getAll().features.length === 0) {
    setNumSelectedFeatures(0);
  }
};

/**
 * * Toolbar Component
 */

const MapboxToolbar = ({
  mode,
  setMapMode,
  mapRef,
  drawRef,
  numSelectedFeature,
  setNumSelectedFeature,
}: {
  mode: MapboxModes;
  setMapMode: SetAtom<[SetStateAction<MapboxModes>], void>;
  mapRef: MutableRefObject<Map | null>;
  drawRef: MutableRefObject<MapboxDraw | null>;
  numSelectedFeature: number;
  setNumSelectedFeature: SetAtom<[SetStateAction<number>], void>;
}) => {
  /**
   * ? Hooks
   */
  const { darkMode } = useDarkMode();
  const [scope, animate] = useAnimate();

  const [infoBox, setInfoBox] = useAtom(infoBoxIsOpenAtom);

  /**
   * ? TW Style Classnames
   */
  // ? map toolbar and instructions parent container
  const MapToolbarClass = cn(
    "flex absolute w-[15svw] left-0 z-0 m-2 p-2 top-0 w-full justify-between"
  );

  const ToolBarContainerClass = cn("flex flex-col fixed ");

  // ? toolbar select button
  const ToolbarSelectButtonClass = cn(
    `bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500`,
    drawRef.current?.getMode() === MapboxModes.DEFAULT_MODE && "bg-green-500"
  );

  // ? toolbar draw polygon button
  const ToolbarDrawPolygonButtonClass = cn(
    `bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500`,
    drawRef.current?.getMode() === MapboxModes.DRAWING_MODE && "bg-green-500"
  );

  // ? Merge multiple polygons button
  const ToolbarPolygonMergeButtonClass = cn(
    `bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500`,
    numSelectedFeature > 0 ? "visible" : "invisible"
  );

  // ? Create internal polygonal boundaries in polygon
  const ToolbarPolygonBoundariesButtonClass = cn(
    `bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500`,
    numSelectedFeature > 0 ? "visible" : "invisible"
  );

  // ? Split Polygon up by line sections
  const ToolbarSplitPolygonButtonClass = cn(
    `bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500`,
    numSelectedFeature > 0 ? "visible" : "invisible"
  );

  // ? Ungroup polygons
  const ToolbarUncombinePolygonButtonClass = cn(
    `bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500`,
    numSelectedFeature > 0 ? "visible" : "invisible"
  );

  // ? group polygons
  const ToolbarCombinePolygonButtonClass = cn(
    `bg-forestgreen-200 rounded-lg m-2 p-2 hover:bg-green-500`,
    numSelectedFeature > 0 ? "visible" : "invisible"
  );

  // ? guide parent container
  const GuideParentClass = cn(
    `fixed z-10 right-[10%] bg-forestgreen-${darkMode ? "200" : "800"}`,
    `rounded-l-lg  w-full max-w-[300px] my-[10%]`,
    `opacity-0 grid select-none`
  );

  // ? guide header
  const GuideHeaderClass = cn(
    "text-2xl font-bold m-2 flex justify-between items-center",
    `text-${darkMode ? "black" : "white"}`
  );

  // ? guide paragraph
  const GuideParagraphClass = cn("text-sm m-2");

  // ? guide icon in paragraph
  const GuideIconInParagraphClass = cn(
    "inline",
    `text-${darkMode ? "gray-400" : "white"}`
  );

  // ? reset to initial mapstate button
  const ResetInitialMapStateButtonClass = cn(
    `bg-forestgreen-200 rounded-lg my-1 p-2 hover:bg-green-500 border-[1px] border-black text-black`
  );

  // ? show/hide guide icon
  const ShowHideGuideButtonClass = cn(
    `bg-forestgreen-200 rounded-lg ml-4 mr-5 my-1 p-2 hover:bg-green-500 border-[1px] border-black text-black`
  );

  useEffect(() => {
    const openSequence = [
      [
        "div",
        { opacity: 1, scale: 1, filter: "blur(0px)" },
        {
          ease: [0.08, 0.65, 0.53, 0.96],
          duration: 0.5,
        },
      ],
    ] satisfies AnimationSequence;

    const closeSequence = [
      [
        "div",
        { opacity: 0, scale: 0.3, filter: "blur(20px)" },
        {
          ease: [0.08, 0.65, 0.53, 0.96],
          duration: 0.5,
        },
      ],
    ] satisfies AnimationSequence;

    const InfoAnimations = infoBox ? openSequence : closeSequence;

    animate(InfoAnimations);
  }, [animate, infoBox]);

  return (
    <div className={MapToolbarClass}>
      <div className={ToolBarContainerClass}>
        <button
          title="Select or Drag"
          className={ToolbarSelectButtonClass}
          onClick={() => {
            changeMode(MapboxModes.DEFAULT_MODE, setMapMode, drawRef, mapRef);
          }}
        >
          <SelectIcon />
        </button>
        <button
          title="Draw Polygon Mode"
          className={ToolbarDrawPolygonButtonClass}
          onClick={() => {
            changeMode(MapboxModes.DRAWING_MODE, setMapMode, drawRef, mapRef);
          }}
        >
          <PolygonVerticesIcon />
        </button>
        <button
          title="Merge Polygons"
          className={ToolbarPolygonMergeButtonClass}
          onClick={() => {
            changeMode(MapboxModes.UNION_POLYGON, setMapMode, drawRef, mapRef);
          }}
        >
          <MergeIcon />
        </button>
        <button
          title="Internal Polygon Boundaries"
          className={ToolbarPolygonBoundariesButtonClass}
          onClick={() => {
            changeMode(MapboxModes.CUT_POLYGON, setMapMode, drawRef, mapRef);
          }}
        >
          <SelectionXIcon />
        </button>
        <button
          title="Cut Polygons"
          className={ToolbarSplitPolygonButtonClass}
          onClick={() => {
            changeMode(
              MapboxModes.SPLIT_POLYGON_MODE,
              setMapMode,
              drawRef,
              mapRef
            );
          }}
        >
          <ScissorsIcon />
        </button>
        <button
          title="Ungroup Polygons"
          className={ToolbarUncombinePolygonButtonClass}
          onClick={() => {
            drawRef.current?.uncombineFeatures();
          }}
        >
          <UngroupItemsIcon />
        </button>
        <button
          title="Group Polygons"
          className={ToolbarCombinePolygonButtonClass}
          onClick={() => {
            drawRef.current?.combineFeatures();
          }}
        >
          <GroupItemsIcon />
        </button>
      </div>
      <div className="fixed z-10 right-[10%] ">
        <button
          title="Reset Map"
          className={ResetInitialMapStateButtonClass}
          onClick={() => {
            // to reset to initial map view in singapore
            mapRef.current?.flyTo({
              center: [103.8198, 1.3521],
              duration: 500,
              zoom: 10.5,
              essential: true,
              pitch: 0,
              bearing: 0,
            });
            drawRef.current?.changeMode(MapboxModes.DEFAULT_MODE);
            drawRef.current?.deleteAll();
            mapRef.current?.getLayer(roofLayerID) &&
              mapRef.current?.removeLayer(roofLayerID);
            mapRef.current?.getSource(roofLayerSource) &&
              mapRef.current?.removeSource(roofLayerSource);
            mapRef.current?.getLayer(unconstructedBuildingLayerID) &&
              mapRef.current?.removeLayer(unconstructedBuildingLayerID);
            mapRef.current?.getSource(unconstructedBuildingSource) &&
              mapRef.current?.removeSource(unconstructedBuildingSource);

            setNumSelectedFeature(0);
          }}
        >
          <ResetIcon />
        </button>
        <button
          title="Guide"
          className={ShowHideGuideButtonClass}
          onClick={() => {
            setInfoBox(!infoBox);
          }}
        >
          <InformationIcon />
        </button>
      </div>
      <div ref={scope}>
        <div className={GuideParentClass}>
          {mode.includes(MapboxModes.DEFAULT_MODE) ? (
            <>
              <h2 className={GuideHeaderClass}>Getting Started</h2>
              <p className={GuideParagraphClass}>
                To start off, <strong>click</strong> on the properties to be
                evaluated.
              </p>
              <p className={GuideParagraphClass}>
                To deselect a property, <strong>right click</strong> on a
                selected property.
              </p>
              <p className={GuideParagraphClass}>
                If you wish to evaluate only a section of a property, or a
                property that has yet to be constructed, click on{" "}
                <PolygonVerticesIcon className={GuideIconInParagraphClass} /> to
                start drawing.
              </p>
            </>
          ) : (
            <>
              <h2 className={GuideHeaderClass}>Getting Started</h2>
              <p className={GuideParagraphClass}>
                To begin drawing, <strong>click</strong> on the map to add a
                vertex. <strong>Double click</strong> to finalise your drawing.
              </p>
              <p className={GuideParagraphClass}>
                To remove your drawing, select the drawing and click on{" "}
                <TrashIcon className={GuideIconInParagraphClass} />.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { MapboxToolbar, removeAllSelectedPolygons };
