import { useAtom } from "jotai";
import { darkModeAtom } from "../states/atoms";
import { ExtractAtomValue, SetStateAction } from "jotai";
import { SetAtom } from "@/types/global";

type darkMode = ExtractAtomValue<typeof darkModeAtom>;

interface IdarkMode {
  darkMode: darkMode;
  setDarkMode: SetAtom<[SetStateAction<darkMode>], void>;
}

// sets these as local storage
export const useDarkMode = (): IdarkMode => {
  // states
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);

  return { darkMode, setDarkMode };
};
